.App {
  text-align: center;
  display:grid;
  grid-template-rows: 75px auto auto 100px;
  grid-template-areas:
    'header' 
    'top-content'
    'main-content'
    'footer';
 max-width:100vw;
  height: 96vh;
  margin-right:10em;
  margin-left:10em;
}

#header {
  grid-area: header;
  max-height:75px;
  max-width: max-content;
}
#header div {
  display:inline-block;
}

#top-content {
  grid-area: top-content;
  text-align: justify;
  font-size: large;
  display:grid;
  grid-template-columns: 75% auto;
}

#header-icon {
  padding-left:1em;

}

#main-content {
  grid-area: main-content;
}

#footer {
  grid-area: footer;
  
}




